import { Response } from '../../../common/types';
import { apiSlice } from '../apiSlice';

export const reportsApi = apiSlice.injectEndpoints({
  endpoints: builder => ({
    getTradePoints: builder.query<Response<any>, any>({
      query: () => ({url: '/dataservice/trade_point/list'}),
    }),
    getReportsTypes: builder.query<Response<ReportsResponseType>, any>({
      query: () => ({url: '/dataservice/report/typelist'}),
    }),
    getCategoriesTree: builder.query<Response<CategoryResponseType>, any>({
      query: () => ({url: '/dataservice/category/treelist'}),
    }),
    getCategoryProducts: builder.query<any, any>({
      query: ({id, tradePointId}: {id: string, tradePointId: number}) => ({
        url: `/dataservice/product/category/${id}?trade_point_id=${tradePointId}`,
      }),
    }),
    searchProducts: builder.query<any, any>({
      query: ({name}: {name: string}) => ({url: `/dataservice/product/search?name=${name}`}),
    }),
    getReports: builder.query<Response<any>, any>({
      query: () => ({url: '/dataservice/report/list'}),
    }),
    createReport: builder.mutation<Response<any>, any>({
      query: ({path, body}: {path: string, body: any}) => ({
        url: `/dataservice/report/${path}`,
        method: 'POST',
        body,
      }),
    }),
  }),
});

export const {
  useGetTradePointsQuery,
  useGetReportsTypesQuery,
  useSearchProductsQuery,
  useGetCategoriesTreeQuery,
  useGetCategoryProductsQuery,
  useGetReportsQuery,
  useCreateReportMutation,
} = reportsApi;
