import {Button, Tab, Tabs} from '@mui/material';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useLogoutMutation } from '../../../redux/api/authApi';
import { Nav, NavMenu, NavBtn } from './NavbarElements';
import { useAppDispatch } from '../../../redux/store';
import { logOut } from '../../../redux/slices/authSlice';
import AdminMenu from './AdminMenu';
import './styles.scss';

const Navbar = () => {
  const [logout] = useLogoutMutation();

  const dispatch = useAppDispatch();

  const [tab, setTab] = useState('/analytics');

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const navigate = useNavigate();

  const handleChange = (_: React.SyntheticEvent, newValue: string) => {
    if (newValue !== 'menu') {
      setTab(newValue);
      navigate(newValue);
    }
  };

  const open = Boolean(anchorEl);

  const openMenu = (event: React.MouseEvent<any, MouseEvent>) => {
    setAnchorEl(event.currentTarget);
  };

  const closeMenu = () => {
    setAnchorEl(null);
  };

  const onItemPress = (event: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
    const { route } = event.currentTarget.dataset;
    setTab('menu');
    closeMenu();
    if (route) navigate(route);
  };

  const onLogout = () => {
    logout();
    dispatch(logOut());
  };

  return (
    <>
      <Nav>
        <NavMenu>
          <Tabs
            value={tab}
            onChange={handleChange}
            aria-label='Меню'
            className='menu-flex-flow'
            sx={{
              color: 'black',
              '& button.Mui-selected': {
                fontWeight: '500',
                fontSize: '10',
                color: 'black',
              },
            }}
            TabIndicatorProps={{
              sx: {
                background: 'transparent',
              },
            }}
          >
            <Tab label='Новые отчеты' value='reports/new' />
            <Tab label='Заказанные отчеты' value='reports/list' />
            <Tab label='Категории' value='/sales' />
            <Tab label='Торговые точки' value='/sales/trade-points' />
            <Tab label='Обеспеченность запасами' value='/sales/available-products' />
            <Tab label='Упущенная прибыль' value='/sales/lost-profit' />
            <Tab label='Управление' value='menu' onClick={openMenu} />
          </Tabs>
        </NavMenu>
        <NavBtn>
          <Button variant='text' onClick={onLogout}>
            Выход
          </Button>
        </NavBtn>
        <AdminMenu
          id='admin-menu'
          anchorEl={anchorEl}
          open={open}
          onClose={closeMenu}
          onItemPress={onItemPress}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}
        />
      </Nav>
    </>
  );
};

export default Navbar;
