import {createTheme, ThemeProvider, Typography} from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { ruRU } from '@mui/material/locale';
import React from 'react';

import './App.css';
import Router from './navigation';

const theme = createTheme({}, ruRU);

function App() {
  return (
    <ThemeProvider theme={theme}>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <div className='demo-version-view'>
          <Typography
            sx={{
              color: 'white',
              fontSize: 14,
              textAlign: 'center',
            }}
          >
            Демонстрационная версия
          </Typography>
        </div>
        <Router />
      </LocalizationProvider>
    </ThemeProvider>
  );
}

export default App;
