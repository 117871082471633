import React, { useState } from 'react';
import moment from "moment";
import {
  Paper,
  Table, TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';

import MainContainer from '../../../components/MainContainer/MainContainer';
import {
  useGetReportsQuery,
  useGetTradePointsQuery,
} from "../../../redux/api/reportsApi";
import {headerCellSX} from "../../../utils/helpers/table";

import './styles.scss';


const getStart = () => moment().subtract(1, 'month').startOf('day').toDate();
const getEnd = () => moment().endOf('day').toDate();

const reportStatuses: Record<string, string> = {
  NEW: 'Новый',
  IN_PROCESS: 'В процессе',
  READY: 'Готов',
  ERROR: 'Ошибка',
};

const getDate = (date: string) => date && moment(date).format('HH:mm DD.MM.YYYY') || '';

const getReportStatus = (status: string) => {
  return status && reportStatuses[status] || '';
};

const ReportsList = () => {

  const {data: reports} = useGetReportsQuery({});

  console.log('reports', reports);

  return (
    <>
      <MainContainer title='Список заказанных отчетов' maxWidth={false}>
        <TableContainer component={Paper} sx={{ mt: '25px' }}>
          <Table sx={{ minWidth: 650 }} aria-label='simple table'>
            <TableHead>
              <TableRow>
                <TableCell sx={headerCellSX} width='auto' align='left'>
                  ID
                </TableCell>
                <TableCell sx={headerCellSX} width='auto' align='left'>
                  Название
                </TableCell>
                <TableCell sx={headerCellSX} width='auto' align='left'>
                  Заказан
                </TableCell>
                <TableCell sx={headerCellSX} width='auto' align='left'>
                  Сформирован
                </TableCell>
                <TableCell sx={headerCellSX} width='auto' align='left'>
                  Стаус
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {reports?.body?.length ? (
                ([...reports.body] as any[]).sort((a: any, b: any) => b.id - a.id).map((data: any) => (
                  <TableRow
                    key={data.id}
                    sx={{
                      '&:last-child td, &:last-child th': { border: 0 },
                      cursor: 'pointer',
                    }}
                  >
                    <TableCell align='left'>{data?.id || ''}</TableCell>
                    <TableCell align='left'>{data?.idReport?.name || ''}</TableCell>
                    <TableCell align='left'>{getDate(data?.startedAt)}</TableCell>
                    <TableCell align='left'>{getDate(data?.finishedAt)}</TableCell>
                    <TableCell align='left'>{getReportStatus(data?.status)}</TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell align='center' colSpan={4}>
                    Нет данных
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </MainContainer>
    </>
  );
};


export default ReportsList;
