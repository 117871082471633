export const CATEGORY_CARD = 'CATEGORY_CARD';
export const CATEGORY_SSA = 'CATEGORY_SSA';
export const CATEGORY_ARIMA = 'CATEGORY_ARIMA';
export const CATEGORY_COMPARE = 'CATEGORY_COMPARE';

export const CATEGORY_REPORTS = [
  CATEGORY_CARD,
  CATEGORY_SSA,
  CATEGORY_ARIMA,
  CATEGORY_COMPARE,
];

export const PRODUCT_CARD = 'PRODUCT_CARD';
export const PRODUCT_SSA = 'PRODUCT_SSA';
export const PRODUCT_ARIMA = 'PRODUCT_ARIMA';
export const PRODUCT_COMPARE = 'PRODUCT_COMPARE';

export const PRODUCT_REPORTS = [
  PRODUCT_CARD,
  PRODUCT_SSA,
  PRODUCT_ARIMA,
  PRODUCT_COMPARE,
];

export const JOINT_SALES = 'JOINT_SALES';
export const SALE_CORRELATION = 'SALE_CORRELATION';

export const CARD_REPORTS = [
  PRODUCT_CARD,
  PRODUCT_SSA,
  PRODUCT_ARIMA,
  CATEGORY_CARD,
  CATEGORY_SSA,
  CATEGORY_ARIMA,
];

export const COMPARE_REPORTS = [
  PRODUCT_COMPARE,
  CATEGORY_COMPARE,
];

export const SALES_REPORTS = [
  JOINT_SALES,
  SALE_CORRELATION,
];

export const REPORT_TYPES: Record<string, string> = {
  [CATEGORY_CARD]: 'category_card',
  [CATEGORY_SSA]: 'category_ssa',
  [CATEGORY_ARIMA]: 'category_arima',
  [CATEGORY_COMPARE]: 'category_compare',
  [PRODUCT_CARD]: 'product_card',
  [PRODUCT_SSA]: 'product_ssa',
  [PRODUCT_ARIMA]: 'product_arima',
  [PRODUCT_COMPARE]: 'product_compare',
  [JOINT_SALES]: 'joint_sales',
  [SALE_CORRELATION]: 'sale_correlation',
};
