import React, { useCallback, useState } from 'react';
import MainContainer from '../../../components/MainContainer/MainContainer';
import {
  Autocomplete,
  Button, CircularProgress, Dialog, MenuItem, Select, TextField,
  Typography,
} from '@mui/material';

import 'react-checkbox-tree/lib/react-checkbox-tree.css';
import {DateTimePicker} from '@mui/x-date-pickers/DateTimePicker';
import {
  useCreateReportMutation,
  useGetReportsTypesQuery,
  useGetTradePointsQuery,
  useSearchProductsQuery
} from "../../../redux/api/reportsApi";
import CategoriesTree from "./categories-tree";

import './styles.scss';
import {Stack} from "@mui/system";
import {
  CARD_REPORTS,
  CATEGORY_COMPARE,
  CATEGORY_REPORTS, COMPARE_REPORTS,
  PRODUCT_COMPARE,
  PRODUCT_REPORTS,
  REPORT_TYPES
} from "./constants";
import moment from "moment";

const getStart = () => moment().subtract(1, 'month').startOf('day').toDate();
const getEnd = () => moment().endOf('day').toDate();

const success = 'Запрос на формирование отчета успешно отправлен!';
const error = 'Ошибка отправки запроса на формирование отчета';

const NewReports = () => {
  const [report, setReport] = useState<ReportType>();
  const [tradePoint, setTradePoint] = useState<any>();
  const [start, setStart] = useState<Date | null>(getStart());
  const [end, setEnd] = useState<Date | null>(getEnd());
  const [name, setName] = useState('');
  const [selected, setSelected] = useState<ItemType[]>();
  const [loading, setLoading] = useState(false);
  const [msg, setMsg] = useState('');

  // <autocomplete
  const [open, setOpen] = useState(false);
  // const [options, setOptions] = React.useState<readonly Film[]>([]);
  // const [loading, setLoading] = React.useState(false);

  // const handleOpen = () => {
  //   setOpen(true);
  //   (async () => {
  //     setLoading(true);
  //     await sleep(1e3); // For demo purposes.
  //     setLoading(false);
  //
  //     setOptions([...topFilms]);
  //   })();
  // };
  //
  // const handleClose = () => {
  //   setOpen(false);
  //   setOptions([]);
  // };
  // autocomplete>

  const {data: tradePoints} = useGetTradePointsQuery({});

  console.log('tradePoints', tradePoints);

  const {data: reports} = useGetReportsTypesQuery({});
  const {data: products, isLoading} = useSearchProductsQuery(
    {name},
    {skip: !name}
  );
  const [create] = useCreateReportMutation();

  console.log('reports', reports);

  const openFiltersDialog = (report: any) => {
    console.log(report);
    setTradePoint(undefined);
    setSelected(undefined);
    setStart(getStart());
    setEnd(getEnd());
    setReport(report);
    setMsg('');
  };

  const createReport = () => {
    if (!report?.code || !tradePoint || !start || !end || !selected?.length) {
      return;
    }
    setLoading(true);
    const path = REPORT_TYPES[report.code];
    const body: Record<string, any> = {
      tradePointId: tradePoint,
      start: start.toISOString(),
      end: end.toISOString(),
    };
    if (CARD_REPORTS.includes(report.code)) {
      body.id = selected[0].id;
    } else if (COMPARE_REPORTS.includes(report.code)) {
      body.ids = selected.map((p) => p.id);
    }
    create({path, body})
      .unwrap()
      .then((res) => {
        console.log('create res', res);
        setLoading(false);
        setMsg(success);
      })
      .catch((e) => {
        console.log('create error', e);
        setLoading(false);
        setMsg(error);
      });
  };

  const isProductCompare = report?.code === PRODUCT_COMPARE;
  const isCategoryCompare = report?.code === CATEGORY_COMPARE;

  return (
    <>
      <MainContainer title='Новые отчеты' maxWidth={false}>
        <div className='list'>
          {reports?.body?.map((item, index) => (
            <div key={index} className='list-item'>
              <Button variant='text' onClick={() => openFiltersDialog(item)}>{item.name}</Button>
            </div>
          ))}
        </div>
      </MainContainer>
      {report ? (
        <Dialog
          fullWidth
          open
          onClose={() => openFiltersDialog(undefined)}
          scroll='paper'
          aria-labelledby='scroll-dialog-title'
          aria-describedby='scroll-dialog-description'
        >
          <MainContainer>
            <Stack spacing={2}>
              {loading
                ? <CircularProgress />
                : msg
                  ? <Typography sx={{textAlign: 'center'}}>{msg}</Typography>
                  : (<>
                    {report?.name ? (
                      <Typography sx={{textAlign: 'center'}}>
                        <strong>{report.name}</strong>
                      </Typography>
                    ) : null}
                    {tradePoints?.body?.length ? (
                      <>
                        <Typography sx={{textAlign: 'left'}}>
                          <strong>Торговая точка</strong>
                        </Typography>
                        <Select
                          value={tradePoint}
                          onChange={(e) => {
                            setTradePoint(e.target.value);
                            console.log('setTradePoint e.target.value', e.target.value);
                          }}
                        >
                          {tradePoints.body.map((item: any) => (
                            <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>
                          ))}
                        </Select>
                      </>
                    ) : null}
                    {tradePoint ? (
                      <>
                        {CATEGORY_REPORTS.includes(report?.code) ? (
                          <CategoriesTree
                            multiple={isCategoryCompare}
                            selected={selected}
                            setSelected={setSelected}
                            tradePointId={tradePoint}
                          />
                        ) : null}
                        {PRODUCT_REPORTS.includes(report?.code) ? (
                          <>
                            {/*<TextField id="outlined-basic" label="Название товара" variant="outlined" />*/}
                            {/*<Autocomplete
                        multiple
                        id="tags-outlined"
                        options={products?.body || []}
                        getOptionLabel={(option: any) => option.name}
                        // defaultValue={[top100Films[1]]}
                        filterSelectedOptions
                        renderInput={(params) => {
                          console.log('params', params);
                          return <TextField
                            {...params}
                            label="Название товара"
                            placeholder="Название товара"
                            onChange={(e) => setName(e.target.value || '')}
                          />
                        }}
                      />*/}
                            <Autocomplete
                              id="asynchronous-demo"
                              // sx={{ width: 300 }}
                              open={open}
                              onSelect={(e) => console.log('on select', e)}
                              onChange={(e, v) => {
                                console.log('on change', e, v);
                                if (isProductCompare) {
                                  if (selected?.some((p) => p.id === v.id)) {
                                    setSelected(selected?.filter((p) => p.id !== v.id) || []);
                                  } else {
                                    setSelected([...(selected || []), v]);
                                  }
                                } else if (v) {
                                  setSelected([v]);
                                } else {
                                  setSelected(undefined);
                                }
                              }}
                              onOpen={() => {
                                setOpen(true);
                              }}
                              onClose={() => {
                                setOpen(false);
                              }}
                              isOptionEqualToValue={(option: any, value: any) => false}
                              getOptionLabel={(option: any) => option.name}
                              options={products?.body || []}
                              loading={isLoading}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="Поиск по названию товара"
                                  placeholder="Начните вводить имя товара для поиска"
                                  onChange={(e) => setName(e.target.value || '')}
                                  InputProps={{
                                    ...params.InputProps,
                                    endAdornment: (
                                      <>
                                        {isLoading ? <CircularProgress color="inherit" size={20} /> : null}
                                        {params.InputProps.endAdornment}
                                      </>
                                    ),
                                  }}
                                />
                              )}
                            />
                            <CategoriesTree
                              isProducts
                              multiple={isProductCompare}
                              selected={selected}
                              setSelected={setSelected}
                              tradePointId={tradePoint}
                            />
                          </>
                        ) : null}
                        <Stack spacing={2} direction="row">
                          <DateTimePicker
                            ampm={false}
                            inputFormat='DD.MM.YYYY HH:mm:ss'
                            label="с"
                            value={start}
                            onChange={(date) => setStart(date)}
                            renderInput={(params) => <TextField {...params} />}
                          />
                          <DateTimePicker
                            ampm={false}
                            label="по"
                            value={end}
                            inputFormat='DD.MM.YYYY HH:mm:ss'
                            onChange={(date) => setEnd(date)}
                            renderInput={(params) => <TextField {...params} />}
                          />
                        </Stack>
                        <Stack spacing={2} direction="row" alignContent="center" justifyContent="flex-end">
                          <Button
                            variant="outlined"
                            size="small"
                            color="error"
                            onClick={() => setReport(undefined)}
                          >
                            Закрыть
                          </Button>
                          <Button
                            variant="outlined"
                            size="small"
                            onClick={createReport}
                          >
                            Сформировать
                          </Button>
                        </Stack>
                      </>
                    ) : null}
                  </>)}
            </Stack>
          </MainContainer>
        </Dialog>
      ) : null}
    </>
  );
};


export default NewReports;
